import React from 'react';
import './MatchTable.css';

const MatchTable = () => {
  const teams = [
    { id: 1, flag: '🇺🇸', name: 'United States', },
    { id: 2, flag: '🇺🇾', name: 'Uruguay' },
    { id: 3, flag: '🇵🇦', name: 'Panama' },
    { id: 4, flag: '🇧🇴', name: 'Bolivia' },
  ];

  return (
    <div className="table-container">
      <table className="match-table">
        <thead>
          <tr>
            <th>Team</th>
            <th>G</th>
            <th>W</th>
            <th>D</th>
            <th>L</th>
            <th>GD</th>
            <th>SF</th>
            <th>CS</th>
            <th>Pts</th>
          </tr>
        </thead>
        <tbody>
          {teams.map(team => (
            <tr key={team.id}>
              <td>
                <span className="flag">{team.flag}</span>
                <span>{team.name}</span>
              </td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MatchTable;